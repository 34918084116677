import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import HelmetTitle from "@/components/HelmetTitle";
import LoadingSkeletonCard from "@/components/LoadingSkeletonCard";
import ErrorAlert from "@/components/ErrorAlert";
import { useSearchData, useTargets } from "@/services/hooks";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";
import SearchAppBar from "./SearchAppBar";
import TargetCardContainer from "./TargetCardContainer";
import TargetGridContainer from "./TargetGridContainer";

export default function SearchResults() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);

  const searchKey = searchParams.get("searchKey");
  const currentView = searchParams.get("currentView");
  // const displayMode = searchParams.get("displayMode") || "card";

  const [displayMode, setDisplayMode] = useState(
    searchParams.get("displayMode") || "card"
  );

  const { data: searchData } = useSearchData({ uid: searchKey });
  const { data: targets = [], isLoading } = useTargets({
    searchUid: searchKey,
    stage: currentView,
  });

  const renderTargets = () => {
    if (isLoading) {
      return (
        <Box sx={{ mt: 3 }}>
          {[...Array(5)].map((_, index) => (
            <LoadingSkeletonCard key={index} />
          ))}
        </Box>
      );
    }

    return displayMode === "card" ? (
      <TargetCardContainer targets={targets} searchData={searchData} />
    ) : (
      <TargetGridContainer targets={targets} searchData={searchData} />
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <HelmetTitle searchData={searchData} />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <LeftNav />

        <Box
          id="targets-container"
          sx={{
            mt: 2,
            mb: 2,
            marginLeft: "150px",
          }}
        >
          <SearchAppBar
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
          />
          {renderTargets()}
        </Box>

        <RightNav />
      </Box>

      <ErrorAlert error={error} setError={setError} />
    </Box>
  );
}
